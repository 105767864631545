<template>
<div v-if="invitevalid == true" style="margin: 50px">
<h2>Sign Up</h2>
      <div class="form-group">
          <label>Username</label>
          <input style="border: 2px solid #484848" placeholder="Username" v-model='user.username' class='form-control' />
      </div>
      <div class="form-group">
          <label>Email</label>
          <input style="border: 2px solid #484848" type="email" placeholder="Email" v-model='user.email' class='form-control' />
      </div>
      <div class="form-group">
          <label>Password</label>
          <input style="border: 2px solid #484848" type="password" placeholder="Password" v-model='user.password' class='form-control' />
      </div>
      <br>
      <button style="border: 2px solid #484848" v-on:click='signUp' class='btn btn-primary btn-block'>Sign Up</button><br>
</div>

<div v-if="invitevalid == false">
    <h1 style="text-align: center">Invalid Invite Link. <router-link class="nav-link text-blue" to="/">Go Back</router-link></h1>
</div>
</template>

<script>
import { db } from '../firebaseDb';
import Auth from '@aws-amplify/auth';
export default {
        data() {
            return {
                invitevalid: false,
                user: {
                    username: "",
                    password: "",
                    email: "",
                    fh: "",
                    approved: false,
                },
                userpush: {
                    username: "",
                    email: "",
                    fh: "",
                    approved: false,
                    invitelink: "",
                },
                key: "",
            }
        },
        created() {
            db.collection('pendingusers').onSnapshot((snapshotChange) => {
                snapshotChange.forEach((doc) => {
                    console.log(this.$route.params.id)
                    if(doc.data().invitelink == this.$route.params.id) {
                        this.invitevalid = true;
                        this.user.email = doc.data().email;
                        this.user.fh = doc.data().fh;
                        this.user.approved = doc.data().approved;
                        this.key = doc.id;
                        console.log(doc.data());
                        console.log(this.user.email);
                    }
                })
                });
        },
        methods: {
            async signUp() {
              const { username, password, email, fh } = this.user
              try {
                  await Auth.signUp({
                    username, password, attributes: { email, 'custom:fh': fh, 'custom:approved': this.user.approved }
                  })
                  this.userpush.username = this.user.username;
              this.userpush.email = this.user.email;
              this.userpush.fh = this.user.fh;
              this.userpush.approved = this.user.approved;
              db.collection('pendingusers').doc(this.key)
                .update(this.userpush).then(() => {
                    console.log("Updated!");
                    this.$router.push('/')
                }).catch((error) => {
                    console.log(error);
                });
                  alert("Account Created! Waiting for confirmation...");
                  this.user = {
                          username: '',
                          password: '',
                          email: '',
                        }
                
              } catch (error) {
              if(String(error) !== "Error: Pending sign-in attempt already in progress") {
                if(String(error).includes("validation error") || String(error).includes("did not conform with policy")) {
                  alert("Password security is too low. A password requires at least one captial letter and at least one number")
                }
                if(String(error).includes("auto verified was set to true")) {
                  alert("Sign up requires an email.")
                }
                if(!String(error).includes("validation error") && !String(error).includes("did not conform with policy") && !String(error).includes("auto verified was set to true")) {
                  alert(String(error).split(':')[1]);
                }
              }
              }
             await Auth.signIn(username, password)
    },
        }
    }
</script>